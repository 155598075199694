<template>
  <with-side-navigations side="right" :side-bar-width="50">
    <template #sideNavigation>
      <tabs
        class="max-h-screen overflow-auto"
        @select-tab="activeTabIndex = $event"
      >
        <tab
          :name="$t(`contractors-view.${tabs[0]}`)"
          :selected="activeTabIndex === 0"
          class="mb-56"
        >
          <file-uploader
            :list="uploads('multimedia')"
            type="multimedia"
            target="contractors"
          />
          <file-uploader
            :list="uploads('document')"
            type="document"
            target="contractors"
          />
        </tab>
      </tabs>
    </template>

    <div class="container-fluid bg-gray-lighter">
      <breadcrumbs
        :path="[
          {
            name: $t('contractors-view.contractors'),
            url: { name: 'Contractors' },
          },
          { name: $t('contractors-view.edit-contractor') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>
      <contractor-form
        id="contractorForm"
        class="mb-64"
        :clear="submissionEndSuccessfully"
        :content="contractor"
        :is-loading="submissionInProgress"
        :edit="true"
        @open-sidebar="onOpenSidebar"
        @on-submit="handleSubmission"
        @set-brand="brand = $event"
      />
      <footer-menu>
        <div class="flex justify-center xs:justify-between flex-wrap">
          <div class="flex">
            <btn
              :is-loading="submissionInProgress"
              type="submit"
              form="contractorForm"
              class="w-30 mr-1 md:w-48 md:mr-3"
            >
              {{ $t('common.save') }}
            </btn>
          </div>
          <btn theme="none" class="mt-2 xs:mt-0" @click="$router.go(-1)">
            {{ $t('common.cancel') }}
          </btn>
        </div>
      </footer-menu>
    </div>
  </with-side-navigations>
</template>

<script>
import contractor from '@/views/Dashboard/Contractors/mixins/contractor';
import { mapActions, mapGetters, mapState } from 'vuex';
import VueStore from '@/store';

export default {
  name: 'EditContractor',
  mixins: [contractor],
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      VueStore.dispatch(
        'contractor/fetchContractorDetails',
        to.params.contractorId,
      ),
      VueStore.dispatch('attachment/clearAttachmentList'),
      VueStore.dispatch('attachment/clearUploadQueue'),
      VueStore.dispatch('attachment/fetchAttachments', {
        id: to.params.contractorId,
        target: 'organizations',
        type: 'document',
      }),
      VueStore.dispatch('attachment/fetchAttachments', {
        id: to.params.contractorId,
        target: 'organizations',
        type: 'multimedia',
      }),
    ]);

    next();
  },
  computed: {
    ...mapState('attachment', ['uploadQueue', 'attachments']),
    ...mapGetters('contractor', ['addedPlacesIds', 'removedPlacesIds']),
    ...mapGetters('attachment', ['uploads']),
  },
  methods: {
    ...mapActions('contractor', [
      'editContractor',
      'deleteLogo',
      'addPlaceToContractor',
      'removePlaceFromContractor',
    ]),
    ...mapActions('attachment', ['saveAttachments', 'deleteAttachments']),
    async handleSubmission({ formData, removeLogo = false }) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;

      const data = { ...formData };
      delete data.brandIds;
      delete data.structureIds;
      if (data.logoUrl === '') delete data.logoUrl;
      data.krs = data.krs || null;

      try {
        // if (this.removedPlacesIds.length > 0) {
        //   await this.removePlaceFromContractor({
        //     contractorId: this.contractor.id,
        //     data: this.removedPlacesIds,
        //   });
        // }

        await this.editContractor({ data, contractorId: this.contractor.id });

        // if (this.addedPlacesIds.length > 0) {
        //   await this.addPlaceToContractor({
        //     contractorId: this.contractor.id,
        //     data: { placeIds: this.addedPlacesIds },
        //   });
        // }

        await this.deleteAttachments();
        await this.saveAttachments({ id: this.contractor.id });

        if (removeLogo) await this.deleteLogo(this.contractor.id);

        this.clearAttachmentList();
        this.clearDeleteQueue();
        this.clearUploadQueue();
        this.submissionEndSuccessfully = true;

        this.$router.push({
          name: 'ContractorDetails',
          params: { contractorId: this.contractor.id },
        });

        this.$toast.success(this.$t('contractors-view.contractor-updated'));

        this.clearState();
      } catch (err) {
        this.submissionEndSuccessfully = false;
      }

      this.submissionInProgress = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
